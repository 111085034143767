<template>
    <section class="py-5" style="padding-top:  10px !important;/*! background: #9DB0B0; */padding-bottom: 5px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row" data-v-35cf7caa="">
                        <div class="col-12 col-xl-12" data-v-35cf7caa="" v-for="post in contentData" :key="post.id">
                            <div class="article__content" data-v-35cf7caa="">
                                <p data-v-35cf7caa="">يوميات الزاكي </p>
                            </div>
                            <video controls="" class="plyr" controlslist="nodownload" style="margin-right: 0px;width: 100%;">
                                <source :src="post.source" type="Video/mp4" size="240" >
                            </video>
                                <h3 style="margin-top: 10px;background: #cf0072;border-radius: 16px;padding: 5px;color: #fff;text-align: center;">{{ post.title_ar }}</h3>
                            <div class="article__content" data-v-35cf7caa=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end videos -->
    <!-- catalog -->
    <div class="catalog">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row row--grid" style="background: #4e585980;margin-top: 5px;border-radius: 12px;padding: 5px;margin-right: 3px;margin-left: 3px;">
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="post in getCatByID" :key="post.id">
                            <div class="card">
                                <a @click="getContent(post)" class="card__cover">
                                    <img v-lazy="post.img" alt="">
                                    <svgVideo />
                                </a>
                                <h3 class="card__title"><a @click="getContent(post)">يوميات الزاكي </a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>		
        </div>
    </div>
</template>

<script>
import svgVideo from "@/components/svgData/svgVideo.vue";
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
   components: { 
        svgVideo
    },
    props: {
       content_id: String,
    },

    async setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const route = useRoute();
      const contentData = ref([]);
      const getCatByID = ref([]);

      try {
        await HTTP.get('ContentById.php?CID='+ props.content_id).then((res) => {
           contentData.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
        await HTTP.get('GetContentByCategory.php?cat_id='+cookie.getCookie("cat_id")+'&LIMIT=18&OFFSET=0').then((res) => {
           getCatByID.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      const getContent = (post) => {
        if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "Content", params: { content_id: post.id } });
        } else {
          router.push({ name: "Trailer" });
        }
      };
      return {
        contentData,
        getCatByID,
        getContent
      };
    },
    mounted() {
           const router = useRouter();
        if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        HTTPDSP.post(
            "DSPCheckLogin.php?msisdn="+this.$cookie.getCookie("msisdn")
          ).then((response) => {
              if (response.data.status == 1 ) {
                return 
              } else {
            this.$cookie.removeCookie("msisdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("minutes");
            this.$cookie.removeCookie("Catogery_id");
              }
          });
      
        } else {
            this.$cookie.removeCookie("msisdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("minutes");
            this.$cookie.removeCookie("Catogery_id");
            router.push({ path: "/" });
            
      }
    },
}
</script>

<style>

</style>